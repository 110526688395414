// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import feathers from '@feathersjs/client'
import feathersAuth from '@feathersjs/authentication-client'
import feathersRest from '@feathersjs/rest-client'
import axios from 'axios'
import { HOST_API_FROM_FEATHER } from '../shared/utils/constants'

export const feathersStorage: Storage = {
    getItem: (key) => (typeof window !== 'undefined' ? localStorage.getItem(key) : ''),
    removeItem: (key) => localStorage.removeItem(key),
    setItem: (key, value) => localStorage.setItem(key, value),
    clear: () => localStorage.clear(),
    key: (index) => localStorage.key(index),
    length: typeof localStorage !== 'undefined' ? localStorage.length : 0,
}

const app = feathers()
const restClient = feathersRest(HOST_API_FROM_FEATHER)

app.configure(restClient.axios(axios))

app.configure(
    feathersAuth({
        path: '/authentication',
        storageKey:'jwt',
        storage: feathersStorage,
    }),
)

export const Api = axios.create({
    baseURL: HOST_API_FROM_FEATHER,
    headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
    }
});

export const service = (service: string) => {
    return {
        patch: (id: string, params: any) => Api.put(service + "/" + id, params),
        get: (id: string) => Api.get(service + "/" + id),
        find: (query: any) => Api.get(service, {
            query
        }),
        remove: (id: string) => Api.delete(service + "/" + id),
        create: (params: any) => Api.post(service, params),
    }
}

export const getService = (service: string) => app.service(service);

export default app
