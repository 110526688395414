// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheckimport { useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Col, message, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { UndoOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import {
  selectOrderForUpdate,
  selectOrderPaymentConfirmations,
} from "../../../../redux/selectors/orders.selectors";
import {
  ordersService,
  paymentConfirmationsService,
} from "../../../../shared/services";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  changeOrderPaymentConfirmations,
  setOrdersForUpdate,
} from "../../../../redux";
import { IPaymentConfirmationsFin } from "../../../../redux/@types";
import { decimal } from "../../../../shared/utils/Helper";

const { Title } = Typography;

export const PaymentConfirmation = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const orderForUpdate = useSelector(selectOrderForUpdate);
  const OrderPaymentConfirmations = useSelector(
    selectOrderPaymentConfirmations
  );
  const [isLoading, setIsLoading] = useState(false);
  const getRefreshSingleOrder = async () => {
    if (isLoading) return;
    setIsLoading(true);
    return await ordersService
      .get(Number(id))
      .then((res) => {
        setIsLoading(false);
        return dispatch(setOrdersForUpdate(res));
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        return message.error(err.message);
      });
  };

  const getArrOfPaymentConfirmations = async () => {
    await paymentConfirmationsService
      .find({
        query: { order_id: orderForUpdate?.id, $limit: 9999999999999 },
      })
      //   .then((res: IPaymentConfirmationsFin) =>
      .then((res: any) => dispatch(changeOrderPaymentConfirmations(res.data)));
  };

  useEffect(() => {
    getArrOfPaymentConfirmations();
  }, []);
  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Referencia",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.value)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Respuesta",
      dataIndex: "response",
      key: "response",
    },
    {
      title: "Código respuesta",
      dataIndex: "responseCode",
      key: "responseCode",
    },
    {
      title: "Banco",
      dataIndex: "bank",
      key: "bank",
    },
    {
      title: "Franquicia",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Ip",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
  ];

  return (
    <Col
      span={24}
      style={{
        width: "100%",
        display: "flex",
        margin: "auto",
        // padding: 2.5,
        justifyContent: "flex-start",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <Button
        style={{ marginBottom: "5px" }}
        type="primary"
        shape="circle"
        onClick={getRefreshSingleOrder}
        disabled={isLoading}
        icon={<UndoOutlined />}
      />
      <Table
        style={{ width: "100%" }}
        pagination={false}
        bordered
        columns={columns}
        dataSource={OrderPaymentConfirmations}
      />
    </Col>
  );
};
