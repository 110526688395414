import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { IStore } from "../../../redux/@types";
import { storesService } from "../../../shared/services";
import { useStores, useTableStores } from "../hook";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";

const { Title } = Typography;

export const TableStores = () => {
  const { changeStoresForUpdate, showDeleteConfirm } =
  useTableStores();
  const { setTableProps, isLoading  } = useStores();

  const columns: TableColumns<IStore> = [
    {
      title: "ID",
      width: 30,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID"
    },
    {
      title: "Ciudad",
      key: "location_city_id",
      dataIndex: "location_city_id",
      width: 50,
        // render: (_, item) => {
        //   return (
        //     <Col
        //       style={{
        //         display: "flex",
        //         width: "100%",
        //         flexDirection: "row",
        //       }}
        //     >
        //       <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
        //         {item.user[0]?.name}
        //       </Title>
        //     </Col>
        //   );
        // },
      filterType: "text",
    },
    {
      title: "Foto",
      dataIndex: "cover_path_image",
      width: 30,
      key: "cover_path_image",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          <Avatar
            size={64}
            src={`${PATH_S3_BUCKET}/${item.cover_path_image}`}
          />
        </Col>
      ),
    },
    {
      title: "Nombre",
      width: 70,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "No. Teléfono",
      width: 70,
      dataIndex: "phone",
      key: "phone",
      filterType: "text",
    },
    {
      title: "No. Teléfono 2",
      width: 70,
      dataIndex: "phone2",
      key: "phone2",
      filterType: "text",
    },
    {
      title: "Correo",
      dataIndex:"email",
      key: "email",
      width: 80,
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 38,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeStoresForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<IStore>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={storesService}
      onLoad={setTableProps}
    />
  )
}
