// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Col, Row, Table } from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  DataFulfillmentMatrix,
  useTableForPriceBySomeThing,
} from "../../hooks/fulfillmentCompanyById/useTableForPriceBySomeThing";
import { SimpleInputNumber } from "../../../../shared/components";
import { ColumnsType } from "antd/lib/table";

interface Props {
  type?: string;
  city_id: number;
}

export const TableForPriceBySomeThing = ({ city_id, type }: Props) => {
  const {
    data,
    isCreate,
    isLoading,
    max,
    min,
    price,
    isUpdate,
    idForUpdate,
    formState,

    setFormState,

    changeCreateState,
    changeUpdateState,
    showDeleteConfirmState,
    exitUpdateState,
    createFulfillmentMatrixByCity,
    updateFulfillmentMatrixByCity,
  } = useTableForPriceBySomeThing({
    city_id,
    type,
  });

  const columns: ColumnsType<DataFulfillmentMatrix> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Mínimo",
      dataIndex: "min",
      key: "min",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <SimpleInputNumber
              placeHolder="Mínimo"
              onChange={(val) => setFormState({ ...formState, min: val })}
              value={isUpdate && idForUpdate === item.id ? min : item.min}
              max={999999999999}
              isDisable={false}
            />
            {!isUpdate && (
              <Button
                type="dashed"
                shape="circle"
                onClick={() => changeUpdateState(item)}
                icon={<EditOutlined />}
                size="large"
              />
            )}
            {isUpdate && idForUpdate === item.id && (
              <>
                <Button
                  type="dashed"
                  shape="circle"
                  disabled={isLoading}
                  onClick={() => updateFulfillmentMatrixByCity(item.id)}
                  icon={<SaveOutlined />}
                  size="large"
                />
                <Button
                  type="dashed"
                  danger
                  shape="circle"
                  onClick={exitUpdateState}
                  icon={<CloseOutlined />}
                  size="large"
                />
              </>
            )}
          </Col>
        );
      },
    },
    {
      title: "Máximo",
      dataIndex: "max",
      key: "max",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <SimpleInputNumber
              placeHolder="Máximo"
              onChange={(val) => setFormState({ ...formState, max: val })}
              value={isUpdate && idForUpdate === item.id ? max : item.max}
              max={999999999999}
              isDisable={false}
            />
            {!isUpdate && (
              <Button
                type="dashed"
                shape="circle"
                onClick={() => changeUpdateState(item)}
                icon={<EditOutlined />}
                size="large"
              />
            )}
            {isUpdate && idForUpdate === item.id && (
              <>
                <Button
                  type="dashed"
                  shape="circle"
                  disabled={isLoading}
                  onClick={() => updateFulfillmentMatrixByCity(item.id)}
                  icon={<SaveOutlined />}
                  size="large"
                />
                <Button
                  type="dashed"
                  danger
                  shape="circle"
                  onClick={exitUpdateState}
                  icon={<CloseOutlined />}
                  size="large"
                />
              </>
            )}
          </Col>
        );
      },
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <SimpleInputNumber
              placeHolder="Precio"
              onChange={(val) => setFormState({ ...formState, price: val })}
              value={isUpdate && idForUpdate === item.id ? price : item.price}
              max={999999999999}
              isDisable={false}
            />
            {!isUpdate && (
              <Button
                type="dashed"
                shape="circle"
                onClick={() => changeUpdateState(item)}
                icon={<EditOutlined />}
                size="large"
              />
            )}
            {isUpdate && idForUpdate === item.id && (
              <>
                <Button
                  type="dashed"
                  shape="circle"
                  disabled={isLoading}
                  onClick={() => updateFulfillmentMatrixByCity(item.id)}
                  icon={<SaveOutlined />}
                  size="large"
                />
                <Button
                  type="dashed"
                  danger
                  shape="circle"
                  onClick={exitUpdateState}
                  icon={<CloseOutlined />}
                  size="large"
                />
              </>
            )}
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      // width: 30,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirmState(item.id)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Row
      gutter={[8, 8]}
      style={{
        padding: "10px",
        width: "100%",
      }}
    >
      {!isCreate && type && (
        <Col
          span={7}
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <Button
            type="primary"
            style={{ borderRadius: "4.5px" }}
            icon={<PlusOutlined />}
            size="large"
            onClick={changeCreateState}
          >
            Agregar
          </Button>
        </Col>
      )}
      {isCreate && (
        <>
          <Col
            span={8}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <SimpleInputNumber
              placeHolder="Mínimo"
              onChange={(val) => setFormState({ ...formState, min: val })}
              value={min}
              max={999999999999}
            />
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <SimpleInputNumber
              placeHolder="Máximo"
              onChange={(val) => setFormState({ ...formState, max: val })}
              value={max}
              max={999999999999}
            />
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <SimpleInputNumber
              placeHolder="Precio"
              onChange={(val) => setFormState({ ...formState, price: val })}
              value={price}
              max={999999999999}
            />
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <Button
              type="primary"
              style={{ borderRadius: "4.5px" }}
              size="large"
              disabled={isLoading}
              onClick={createFulfillmentMatrixByCity}
            >
              Crear
            </Button>
          </Col>
        </>
      )}

      <Col
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        <Table columns={columns} dataSource={data} />
      </Col>
    </Row>
  );
};
