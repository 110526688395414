import { message, Modal } from "antd";
import {
  deleteProductCategories,
  productCategoriesChangeModalState,
  refreshTableProductCategories,
  setIsProductCategoriesForUpdate,
  setProductCategoriesForParent,
  setProductCategoriesForUpdate,
} from "../../../redux";
import { IProductCategory } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useProductCategories } from "./useProductCategories";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

export const useTableProductCategories = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useProductCategories();

  const navigateSingleCategory = (user: IProductCategory) => {
    dispatch(setProductCategoriesForParent(user));
    localStorage.setItem("singleCategory", JSON.stringify(user));
    navigate(`/productCategories/${user.id}`);
  };

  const changeProductCategoriesForUpdate = (user: IProductCategory) => {
    dispatch(setIsProductCategoriesForUpdate(true));
    dispatch(setProductCategoriesForUpdate(user));
    dispatch(productCategoriesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la categoría de producto con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar categoría de producto" en caso tal solo oprime en "
          Cancelar".`,
      okText: "Borrar categoría de producto",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteProductCategories(valId)
          .then((res) => {
            dispatch(refreshTableProductCategories(true));
            if(res){
              message.error(`${res}`);
            }else{
              message.success(
                `Se ha borrado con Categoría de producto con id:${valId}`
              );
            }
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    navigateSingleCategory,
    changeProductCategoriesForUpdate,
    showDeleteConfirm,
  };
};
