import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

import {
  workOffersChangeModalState,
  createWorkOffers,
  refreshTableWorkOffers,
  selectWorkOffersForUpdate,
  selectWorkOffersRefreshTable,
  selectIsUpdateWorkOffers,
  setIsWorkOffersForUpdate,
  updateWorkOffers,
} from "../../../redux";
import {
  DataWorkOffers,
  IDataForUpdateWorkOffers,
} from "../../../redux/@types";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import {
  validateCreateWorkOffers,
  validateUpdateWorkOffers,
} from "../../../helpers";

export const useWorkOffers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUpdateWorkOffers = useSelector(selectIsUpdateWorkOffers);
  const dataUpdateWorkOffers = useSelector(selectWorkOffersForUpdate);
  const isRefreshTableWorkOffers = useSelector(selectWorkOffersRefreshTable);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalWorkOffersState = () => {
    dispatch(setIsWorkOffersForUpdate(false));
    navigate(`/workOffers/new`);
  };

  const formMethodsCreate = useForm<DataWorkOffers>({
    resolver: yupResolver(validateCreateWorkOffers),
  });

  const formMethodsUpdate = useForm<IDataForUpdateWorkOffers>({
    resolver: yupResolver(validateUpdateWorkOffers),
  });

  useEffect(() => {
    if (isUpdateWorkOffers) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateWorkOffers });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateWorkOffers, dataUpdateWorkOffers]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateWorkOffers({
      id,
      status: value,
    })
    .then((res: Partial<DataWorkOffers>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado de la oferta con id:${res.id}`
        );
        dispatch(refreshTableWorkOffers(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: IDataForUpdateWorkOffers) => {
    console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateWorkOffers) {
      //console.log("Actualiando===>", data);
      await updateWorkOffers({
        id: data.id,
        path_cover: data.path_cover,
        job: data.job,
        category_id: Number(data.category_id),
        city_id: Number(data.city_id),
        address: data.address,
        company_name: data.company_name,
        salary: data.salary,
        job_type: data.job_type,
        ...(data.description && {description: data.description.replace(/&nbsp;/g, ' ')}),
        ...(data.requirements && {requirements: data.requirements.replace(/&nbsp;/g, ' ')}),
        ...(data.responsibilities && {responsibilities: data.responsibilities.replace(/&nbsp;/g, ' ')}),
      })
        .then(async (res: Partial<DataWorkOffers>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            navigate(`/workOffers`);
            message.success(
              `Se ha actualizado con éxito la oferta con id:${res.id}`
            );
            dispatch(refreshTableWorkOffers(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createWorkOffers({
        ...data,
        city_id: Number(data.city_id),
        category_id: Number(data.category_id),
      })
        .then((res: DataWorkOffers) => {
          setIsLoading(false);
          if (res.id) {
            formMethodsCreate.reset();
            navigate(`/workOffers`);
            message.success(
              `Se ha creado con éxito la oferta con id:${res.id}`
            );
            dispatch(refreshTableWorkOffers(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableWorkOffers) {
      tableProps?.refresh();
      dispatch(refreshTableWorkOffers(false));
    }
  }, [isRefreshTableWorkOffers]);

  return {
    //state
    isUpdateWorkOffers,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalWorkOffersState,
    updateStatus,
  };
};
