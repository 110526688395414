import * as yup from 'yup'

export const validateCreateFulfillmentCompany = yup.object().shape({
    name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    description:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    // path:yup
    // .string()
    // .min(2, 'EL PATH debe tener más de 2 caracteres'),
    type: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['weight', 'units', 'volume', 'price'], 'Debes elegir entre estas opciones'),
    // status: yup
    // .string()
    // .required('Campo requerido.')
    // .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    // integration: yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'LA INTEGRACIÓN debe tener más de 1 caracteres'),
})