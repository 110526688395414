import { IContactSingle } from "../../../redux/@types";
import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { contactsService } from "../../../shared/services";

export const TableContacts = () => {
    const columns: TableColumns<IContactSingle> = [
        {
            title: "ID",
            width: 5,
            dataIndex: "id",
            key: "id",
            filterType: "text",
        },
        {
            title: "Nombres",
            width: 9,
            dataIndex: "first_name",
            key: "first_name",
            filterType: "text",
        },
        {
            title: "Apellidos",
            width: 9,
            dataIndex: "last_name",
            key: "last_name",
            filterType: "text",
        },
        {
            title: "Email",
            width: 9,
            dataIndex: "email",
            key: "email",
            filterType: "text",
        },
        {
            title: "Teléfono",
            width: 9,
            dataIndex: "phone",
            key: "phone",
            filterType: "text",
        },
        {
            title: "Mensaje",
            width: 12,
            dataIndex: "message",
            key: "message",
        },
    ];
    return (
        <Table<IContactSingle>
            columns={columns}
            fetchQueryProps={{
                $sort: { id: -1 },
            }}
            service={contactsService}
            // onLoad={setTableProps}
        />
    )
}
