import { TRootState } from '../@types/redux';

export const selectModalStore= ( state: TRootState) =>state.stores.storeModalIsOpen

export const selectIsUpdateStore= ( state: TRootState) =>state.stores.isUpdateStore

export const selectStoreForUpdate= ( state: TRootState) =>state.stores.storeForUpdate

export const selectStoreRefreshTable= ( state: TRootState) =>state.stores.refreshTableStores

export const selectCitiesForStoresArr= ( state: TRootState) =>state.stores.arrCities