import * as yup from 'yup';

export const validateCreateProductCategories = yup.object().shape({
  name: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
  parent_id: yup
    .number()
    .nullable()
    .typeError('Campo requerido.')
    .positive()
    .min(1, 'Debe de utilizar un numero que no sea negativo'),
  position: yup
    .number()
    .required('Campo requerido.')
    .typeError('Campo requerido.')
    .positive()
    .min(1, 'Debe de utilizar un numero que no sea negativo'),
  status: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
  description: yup
    .string()
    .required('Campo requerido.')
    .nullable()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
});
