import { Button, Col } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table";
import { useBannersProducts, useTableBannersProducts } from "../hooks";
import { ISingleProductForBanner } from '../hooks/useBannersProducts';
import { TableColumns } from '../../../shared/components/ui/Table/types';
import { bannerProductsService } from '../../../shared/services';
export const TableBannersProducts = () => {
    const { showDeleteConfirm } = useTableBannersProducts();
    const { setTableProps,id } = useBannersProducts();

    const columns: TableColumns<ISingleProductForBanner> = [
        {
          title: "ID",
          width: 3,
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Producto",
          width: 10,
          dataIndex: ["product","name"],
          key: "name",
        },
        {
          title: "Acciones",
          key: "operation",
          fixed: "right",
          width: 3,
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  danger
                  onClick={() => showDeleteConfirm(item.id, `${item.product.name}`)}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="large"
                />
              </Col>
            );
          },
        },
      ];

  return (
    <Table<ISingleProductForBanner>
      columns={columns}
      fetchQueryProps={{
        banner_id: id,
        $sort: { id: -1 },
      }}
      service={bannerProductsService}
      onLoad={setTableProps}
    />
  )
}
