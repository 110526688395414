import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useSystemVariables, WrapperModalSystemVariables } from "../../../../modules";
import { selectIsUpdateSystemVariables, selectModalSystemVariables, selectSystemVariablesForUpdate } from "../../../../redux";
import { DataSystemVariables } from "../../../../redux/@types";
import { InputText } from "../inputs";

const { Title } = Typography;

export const ModalSystemVariables = () => {
    const modalSystemVariables = useSelector(selectModalSystemVariables);
    const isUpdateSystemVariables = useSelector(
      selectIsUpdateSystemVariables
    );
    const updateSystemVariables = useSelector(selectSystemVariablesForUpdate);
  
    const {
      isLoading,
      changeModalSystemVariablesState,
      onSubmitCreateOrUpdate,
    } = useSystemVariables();
  
    const {
      control,
      handleSubmit: onSubmit,
      setValue,
      getValues,
      watch,
    } = useFormContext<DataSystemVariables>();
  return (
    <WrapperModalSystemVariables
      title={
        isUpdateSystemVariables
          ? `Actualizando variable: '${updateSystemVariables?.id!}'`
          : `Creando variable`
      }
      open={modalSystemVariables}
      width={"320px"}
      destroyOnClose
      onClose={changeModalSystemVariablesState}
    >
        <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText
          placeHolder="Título genérico"
          name="name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Clave:
        </Title>
        <InputText
          placeHolder="Clave genérica"
          name="key"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Valor:
        </Title>
        <InputText
          placeHolder="https://example.com/esxl"
          name="value"
          control={control}
        />
        </Col>
        <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateSystemVariables
          ? "Actualizar información"
          : "Crear variable de sistema"}
      </Button>
    </WrapperModalSystemVariables>
  )
}
