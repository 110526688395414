import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { selectIsUpdateBrand, selectBrandForUpdate, selectBrandsRefreshTable, setIsBrandForUpdate, brandsChangeModalState, updateBrand, refreshTableBrands, createBrand } from '../../../redux';
import { message } from 'antd';

import { DataBrands } from '../../../redux/@types';
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from '../../../shared/components/ui/Table/types';
import { validateCreateBrands, validateUpdateBrands } from '../../../helpers';


export const useBrands = () => {
    const dispatch = useAppDispatch();

    const isUpdateBrand = useSelector(selectIsUpdateBrand)
    const dataUpdateBrand = useSelector(selectBrandForUpdate)
    const isRefreshTableBrands = useSelector(selectBrandsRefreshTable)

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const changeModalBrandsState = () => {
        dispatch(setIsBrandForUpdate(false))
        dispatch(brandsChangeModalState())
      }

      const formMethodsCreate = useForm<DataBrands>({
        resolver: yupResolver(validateCreateBrands),
      });

      const formMethodsUpdate = useForm<DataBrands>({
        resolver: yupResolver(validateUpdateBrands),
      });

      useEffect(() => {
        if (isUpdateBrand) {
          formMethodsCreate.reset();
          formMethodsUpdate.reset({ ...dataUpdateBrand });
        }

        return () => {
          formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isUpdateBrand, dataUpdateBrand]);
      const updateStatus = async(value: "active" | "inactive", id: any) =>{
        if (isLoading) return;
        setIsLoading(true);
        await updateBrand({
          id,
          status: value,
        })
        .then((res: Partial<DataBrands>) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(bannersChangeModalState());
            message.success(
              `Se ha actualizado con éxito el estado de la marca con id:${res.id}`
            );
            dispatch(refreshTableBrands(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
      }

      const onSubmitCreateOrUpdate = async (data: DataBrands) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateBrand) {
          //console.log("Actualiando===>", data);
          await updateBrand({
            id: data.id,
            name: data.name,
            status: data.status,
            ...(data.description && {description: data.description.replace(/&nbsp;/g, ' ')}),
            priority: data.priority,
            path_logo: data.path_logo,
          })
            .then((res: Partial<DataBrands>) => {
              setIsLoading(false);
              // dispatch(mappingUpdatedUser(res))
              if (res.id) {
                // dispatch(brandsChangeModalState());
                message.success(
                  `Se ha actualizado con éxito la marca con id:${res.id}`
                );
                dispatch(refreshTableBrands(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createBrand(data)
            .then((res: DataBrands) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(brandsChangeModalState());
                message.success(
                  `Se ha creado con éxito la marca con id:${res.id}`
                );
                dispatch(refreshTableBrands(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
      };

      useEffect(() => {
        if (isRefreshTableBrands) {
          tableProps?.refresh();
          dispatch(refreshTableBrands(false));
        }
      }, [isRefreshTableBrands]);

    return {
    //state
    isUpdateBrand,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalBrandsState,
    updateStatus,
  }
}
