import * as yup from 'yup'

export const validateCreateStores = yup.object().shape({
    name: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener más de 1 caracteres'),
    phone: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El TELÉFONO debe tener más de 1 caracteres'),
    phone2: yup
    .string()
    .min(2, 'El TELÉFONO 2 debe tener más de 1 caracteres'),
    email: yup
    .string()
    .email('Introduzca un correo electrónico válido.'),
    schedule: yup
    .string()
    .min(2, 'El HORARIO debe tener más de 1 caracteres'),
    address: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'LA DIRECCIÓN debe tener más de 1 caracteres'),
    location_city_id:yup
    .string()
    .required('Campo requerido.')
    .min(1, 'LA CIUDAD debe tener más de 1 caracteres'),
})