import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectActiveKeyOfFulfillmentCompany } from "../../../../redux";

import {
  fulfillmentCitiesService,
  fulfillmentCompanyService,
} from "../../../../shared/services";

interface DataFulfillmentCities {
  id: number;
  fulfillment_company_id: number;
  location_city_id: number;
  integration_city_id: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  fulfillment_company: FulfillmentCompany;
  location_city: LocationCity;
}

interface FulfillmentCompany {
  id: number;
  name: string;
  description: string;
  path: null;
  type: string;
  status: string;
  integration: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

interface LocationCity {
  id: number;
  name: string;
  integration_id: string;
  state_id: number;
  dane_code: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

export interface DataCompany {
  id: number;
  name: string;
  description: string;
  path: null;
  type: string;
  status: string;
  integration: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

export const usePrice = () => {
  const { id } = useParams();
  const activeKey = useSelector(selectActiveKeyOfFulfillmentCompany);
  const [valueSelector, setValueSelector] = useState<string | undefined>();
  const [arrCitySelector, setArrCitySelector] = useState< Array<number> | []>([])
  const [fulfillmentCities, setFulfillmentCities] = useState<
    DataFulfillmentCities[]
  >([]);
  const [singleFulfillmentCompany, setSingleFulfillmentCompany] =
    useState<DataCompany>();
  const changeSelectValue = (val: string) => {
    setValueSelector(val);
  };

  const getSingleFulfillmentCompany = async () => {
    await fulfillmentCompanyService
      .get(Number(id))
      .then((res) => {
        setSingleFulfillmentCompany(res);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const getFulfillmentCities = async () => {
    fulfillmentCitiesService
      .find({
        query: { fulfillment_company_id: Number(id), $limit: 999999999 },
      })
      .then((res) => {
        setFulfillmentCities(res.data);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (activeKey === 2) {
      getFulfillmentCities();
      getSingleFulfillmentCompany();
    }
  }, [activeKey]);


  const changeArrCitySelect = (idVal: number) =>{
    
    if(!arrCitySelector.find((e)=> e === idVal)){
      setArrCitySelector([...arrCitySelector, idVal])
    } else {
      setArrCitySelector(arrCitySelector.filter((e)=> e !== idVal))
    }
  }
  return {
    //state
    valueSelector,
    arrCitySelector,
    fulfillmentCities,
    singleFulfillmentCompany,
    //methods
    //functions
    changeSelectValue,
    changeArrCitySelect,
  };
};
