import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";

import { contactsDirectoryCategories } from "../../../shared/services";
import { DataDirectoryCategories } from "../../../redux/@types/directoryCategories";
import { useDirectoryCategories, useTableDirectoryCategories } from "../hooks";

export const TableDirectoryCategories = () => {
  const { changeDirectoryCategoriesForUpdate, showDeleteConfirm } =
    useTableDirectoryCategories();
  const { setTableProps } = useDirectoryCategories();

  const columns: TableColumns<DataDirectoryCategories> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre",
      width: 9,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 3,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeDirectoryCategoriesForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataDirectoryCategories>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      scroll={{ x: 'max-content' }}
      service={contactsDirectoryCategories}
      onLoad={setTableProps}
    />
  );
};
