import * as yup from 'yup'

export const validateUpdateBanners = yup.object().shape({
    // path_movile:yup
    // .string()
    // .min(1, 'La PATH MOBILE debe tener más de 1 caracteres'),
    // path_desktop:yup
    // .string()
    // .min(1, 'La PATH ESCRITORIO debe tener más de 1 caracteres'),
    // priority:yup
    // .number().typeError('Campo requerido.')
    // .positive()
    // .min(0, 'Debe de utilizar un numero que no sea negativo'),
    // type:yup
    // .string()
    // .oneOf(["HOME", "OFFERS", "POPUP", "ACADEMY", "FLASH_SALE" ], 'Debes elegir entre estas opciones'),
    // status:yup
    // .string()
    // .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    // action_type:yup
    // .string()
    // .oneOf(['BANNER_PRODUCTS', 'CATEGORY', 'PRODUCT', 'URL', 'COURSE' ], 'Debes elegir entre estas opciones'),
    // action_id:
    // yup.string().when('action_type', {
    //     is: (val: string) => ['BANNER_PRODUCTS', 'CATEGORY', 'PRODUCT', 'COURSE' ].includes(val),
    //     then: yup.string().min(1, 'debe tener más de 1 caracteres'),
    // }),
    // url: yup.string().when('action_type', {
    //     is: 'URL',
    //     then: yup.string().min(2, 'LA URL debe tener más de 1 caracteres'),
    // }),
    // start_date: yup.string().when('type', {
    //     is: 'FLASH_SALE',
    //     then: yup.string().min(1, 'La FECHA DE INICIO debe tener más de 1 caracteres'),
    // }),
    // end_date:yup.string().when('type', {
    //     is: 'FLASH_SALE',
    //     then: yup.string().min(1, 'La FECHA DE FINALIZACIÓN debe tener más de 1 caracteres'),
    // }),
})