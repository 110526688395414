// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from "react";
import { Button, Col, message, Table, Typography } from "antd";
import { EyeOutlined, UploadOutlined, UndoOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useOrderById } from "../../hooks/orderById";
import { ModalOrdersShipped } from "../../../../shared/components";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  selectOrderForUpdate,
  selectOrderShippedForUpdate,
  selectOrderShippedModalIsOpen,
  selectOrderShipping,
} from "../../../../redux/selectors/orders.selectors";
import { shippingService } from "../../../../shared/services";
import {
  changeOrderChangeShipping,
  setOrdersForUpdate,
} from "../../../../redux";
import { decimal } from "../../../../shared/utils/Helper";

const { Title } = Typography;

export const Shippings = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const modalOrdersShipped = useSelector(selectOrderShippedModalIsOpen);
  const orderShippingForUpdate = useSelector(selectOrderForUpdate);
  const orderForUpdate = useSelector(selectOrderShippedForUpdate);
  const orderShipping = useSelector(selectOrderShipping);
  const { changeOrderModal, changeOrderShippedModal, orderShippingShow } =
    useOrderById();
  const status = [3, 5, 6, 7];

  const getRefreshSingleOrder = async () => {
    if (isLoading) return;
    setIsLoading(true);
    getArrOfShipping().then(() => {
      setIsLoading(false);
    });
  };

  const getArrOfShipping = async () => {
    await shippingService
      .find({
        query: { order_id: params?.id, $limit: 9999999999999 },
      })
      //   .then((res: IShippingFin) =>
      .then((res: any) => dispatch(changeOrderChangeShipping(res.data)))
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    getArrOfShipping();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "",
      key: "show_sons",
      render: (_, item) => {
        // console.log({ item });
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <EyeOutlined
              style={{ fontSize: "27px", cursor: "pointer" }}
              onClick={() => changeOrderShippedModal(item)}
            />
          </Col>
        );
      },
    },
    {
      title: "Transportado por",
      dataIndex: ["fulfillment_company", "name"],
      key: "transported_by",
    },
    {
      title: "Total costo envío",
      dataIndex: "price",
      key: "total_shipping_cost",
      render: (item) => (item ? `${decimal(item)}` : "Precio sin asignar"),
    },
    {
      title: "Número de guía",
      dataIndex: "guide_number",
      key: "guide_number",
      render: (item) => `${item || "Número sin definir"}`,
    },
    {
      title: "Estado",
      dataIndex: ["shipping_status", "name"],
      key: "status",
    },
  ];

  return (
    <Col
      span={24}
      style={{
        width: "100%",
        display: "flex",
        margin: "auto",
        // padding: 2.5,
        justifyContent: "flex-start",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        {status.includes(orderShippingForUpdate?.order_status_id) ? (
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={changeOrderModal}
          >
            Generar
          </Button>
        ) : (
          <Title
            style={{
              marginBottom: "3px",
              color: "var(--dark)",
              fontSize: "0.76rem",
            }}
            level={5}
          >
            El estado actual del pedido no permite generar envíos.
          </Title>
        )}
        <div style={{ flexGrow: 1 }} />
        <Button
          style={{ marginBottom: "5px" }}
          type="primary"
          shape="circle"
          onClick={getRefreshSingleOrder}
          disabled={isLoading}
          icon={<UndoOutlined />}
        />
      </div>
      <Table
        style={{ width: "100%" }}
        pagination={false}
        bordered
        columns={columns}
        dataSource={orderShipping}
      />
      {modalOrdersShipped && (
        <ModalOrdersShipped orderShippingShow={orderShippingShow} />
      )}
    </Col>
  );
};
