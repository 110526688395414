/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import lodash from "lodash";

import {
  onUploadFile,
  onUploadFileVersionHurgot,
} from "../../../utils/uploadS3";
import {
  TextDaD1,
  TextDaD2,
  WrapperDragAndDrop,
} from "../../../../modules/products";

interface LoadProps {
  progress: number;
}

function Load(props: LoadProps): JSX.Element {
  return (
    <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
      {/* <Progress percent={props.progress} status="active" /> */}
    </div>
  );
}

interface UploadPropsDefault {
  fileName?: string;
  filePath?: string;
  fileMatch?: false | string | RegExp;
  fileMaxSize?: Number;
  fileUploadProgress?: (progress: number) => void;
  fileMultiple?: boolean;
  onUploadedFile?: (file: any) => void;
  fullWidth?: boolean;
  render?: (props: {
    progress: number;
    onClick: () => void;
  }) => React.ReactNode;
  value?: string;
}

function UploadDragAndDrop(props: UploadPropsDefault) {
  const [progress, setProgress] = useState<number>(0);

  const fileName =
    (props.value &&
      props.value !== "" &&
      props.value.split("/").pop()!.substring(37)) ||
    "";

  function onProgress(value: number) {
    setProgress(value);
  }

  useEffect(() => {
    if (props.fileUploadProgress) props.fileUploadProgress(progress);
  }, [progress]);

  return (
    <React.Fragment>
      {!(progress > 0 && progress <= 100) && (
        <WrapperDragAndDrop
          onClick={(e) => {
            e.currentTarget.getElementsByTagName("input")[0].click();
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const files = e.dataTransfer.files;

            setProgress(0);
            onUploadFileVersionHurgot(
              files,
              lodash.merge(
                {},
                props.fileName ? { name: props.fileName } : {},
                props.filePath ? { path: props.filePath } : {},
                props.fileMatch ? { validate: { match: props.fileMatch } } : {},
                props.fileMaxSize
                  ? { validate: { maxSize: props.fileMaxSize } }
                  : {},
                { onProgress: onProgress }
              )
            ).subscribe({
              next: (file) => {
                setProgress(0);
                if (props.onUploadedFile) props.onUploadedFile(file);
              },
              error: (err) => {
                message.error(
                  err.message || "Algo a salido mal por favor intente de nuevo"
                );
              },
            });
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <input
            style={{ display: "none" }}
            type="file"
            id="uploaderFilesDragAndDrop"
            multiple={props.fileMultiple}
            onChange={(e) => {
              setProgress(0);
              onUploadFile(
                e,
                lodash.merge(
                  {},
                  props.fileName ? { name: props.fileName } : {},
                  props.filePath ? { path: props.filePath } : {},
                  props.fileMatch
                    ? { validate: { match: props.fileMatch } }
                    : {},
                  props.fileMaxSize
                    ? { validate: { maxSize: props.fileMaxSize } }
                    : {},
                  { onProgress: onProgress }
                )
              ).subscribe({
                next: (file) => {
                  setProgress(0);
                  if (props.onUploadedFile) props.onUploadedFile(file);
                },
                error: (err) => {
                  message.error(
                    err.message ||
                      "Algo a salido mal por favor intente de nuevo"
                  );
                },
              });
            }}
          />
          <InboxOutlined
            style={{ fontSize: "40px", color: "var(--primary)" }}
          />
          <TextDaD1>
            Haga clic o arrastre el archivo a esta área para cargarlo
          </TextDaD1>
          <TextDaD2>
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </TextDaD2>
        </WrapperDragAndDrop>
      )}
      {fileName !== "" && fileName}
      {progress > 0 && progress <= 100 && <Load progress={progress} />}
    </React.Fragment>
  );
}

export default UploadDragAndDrop;
