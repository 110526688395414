import * as yup from 'yup'

export const validateUpdateProducts = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    code:yup
    .string()
    .min(2, 'El CÓDIGO debe tener más de 2 caracteres'),
    category_id:yup
    .number().typeError('Campo requerido.'),
    price:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    discount_price:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    // discount_price_whit_tax:yup
    // .number().typeError('Campo requerido.')
    // .positive()
    // .min(0, 'Debe de utilizar un numero que no sea negativo'),
    quantity:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    unit_measure:yup
    .string()
    .nullable(true)
    .min(2, 'LA UNIDAD DE MEDIDA debe tener más de 2 caracteres'),
    quantity_per_unit_measure:yup
    .number().typeError('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    weight:yup
    .number().typeError('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    height:yup
    .number().typeError('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    width:yup
    .number().typeError('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    long:yup
    .number().typeError('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    description:yup
    .string()
    .nullable(true)
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    status:yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    position_more_sales:yup
    .number().typeError('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    user_guide_composition:yup
    .string()
    .nullable(true)
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    user_guide_care:yup
    .string()
    .nullable(true)
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    // course:yup
    // .string()
    // .oneOf(["true", "false"], 'Debes elegir entre estas opciones'),
})