import { Button, Col, Image, message, Typography } from "antd";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import {
  useWorkOffersCategories,
  WrapperModalWorkOffersCategories,
} from "../../../../modules";

import {
  selectWorkOffersCategoriesForUpdate,
  selectIsUpdateWorkOffersCategories,
  selectModalWorkOffersCategories,
} from "../../../../redux";
import { DataWorkOffersCategories } from "../../../../redux/@types";
import { InputNumber, InputSelect, InputText } from "../inputs";

const { Title } = Typography;

export const ModalWorkOffersCategories = () => {
  const modalWorkOffersCategories = useSelector(
    selectModalWorkOffersCategories
  );
  const isUpdateWorkOffersCategories = useSelector(
    selectIsUpdateWorkOffersCategories
  );
  const updateWorkOffersCategories = useSelector(
    selectWorkOffersCategoriesForUpdate
  );

  const {
    isLoading,
    changeModalWorkOffersCategoriesState,
    onSubmitCreateOrUpdate,
  } = useWorkOffersCategories();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataWorkOffersCategories>();

  return (
    <WrapperModalWorkOffersCategories
      title={
        isUpdateWorkOffersCategories
          ? `Actualizando categoría de trabajo: '${updateWorkOffersCategories?.id!}'`
          : `Creando categoría de trabajo`
      }
      open={modalWorkOffersCategories}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalWorkOffersCategoriesState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="Estilista" name="name" control={control} />
      </Col>
      {isUpdateWorkOffersCategories && (
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Estado:
          </Title>
          <InputSelect
            name="status"
            control={control}
            dataOptions={[
              { title: "Activo", value: "active" },
              { title: "Inactivo", value: "inactive" },
            ]}
            label="Estado"
          />
        </Col>
      )}
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Prioridad:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="8"
          name="position"
          control={control}
          min
        />
      </Col>

      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateWorkOffersCategories
          ? "Actualizar información"
          : "Crear categoría de trabajo"}
      </Button>
    </WrapperModalWorkOffersCategories>
  );
};
