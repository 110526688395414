import { IStoresState } from "../@types";
import { storesActions } from "../action-types";
import { IGenericArrStores, IStore } from '../@types/stores';

type storesActionType =
| { type: typeof storesActions.STORES_CHANGE_MODAL_STATE }
| { type: typeof storesActions.GET_STORE_FOR_UPDATE; payload: IStore }
| { type: typeof storesActions.IS_STORE_FOR_UPDATE; payload: boolean }
| { type: typeof storesActions.SWITCH_STORES_REFRESH_TABLE; payload: boolean }
| { type: typeof storesActions.GET_CITIES_FOR_STORES; payload: IGenericArrStores[] }

const INITIAL_STATE: IStoresState = {
    storeForUpdate: undefined,
    isUpdateStore: false,
    refreshTableStores: false,
    storeModalIsOpen: false,
    arrCities: [],
}

export default function storesReducer(state: IStoresState = INITIAL_STATE, action: storesActionType ): IStoresState {
    switch ( action.type ) {
        case storesActions.STORES_CHANGE_MODAL_STATE:
            return{
                ...state,
                storeModalIsOpen: !state.storeModalIsOpen
            }
        case storesActions.GET_STORE_FOR_UPDATE:
            return{
                ...state,
                storeForUpdate: action.payload
            }
        case storesActions.IS_STORE_FOR_UPDATE:
            return{
                ...state,
                isUpdateStore: action.payload
            }
        case storesActions.SWITCH_STORES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableStores: action.payload
            }
        case storesActions.GET_CITIES_FOR_STORES:
            return{
                ...state,
                arrCities: action.payload
            }
        default:
            return state
    }
}