import { Button, Col, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { contactsService } from "../../../shared/services";
import { AdminMenuLayout } from "../../../shared/components";
import { WrapperCardContacts } from "./Styled";
import { TableContacts } from "./TableContacts";

export const Contacts = () => {

    const handleDownload = async () => {
        await contactsService.find({ query: { $client: { exportData: 'true' } } }).then((res) => {
            window.open(res.data[0].objectUrl, '_blank')
        })
    }
  return (
    <AdminMenuLayout pageDescription="Contacts" title="Contacts">
      <WrapperCardContacts>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              onClick={handleDownload}
            >
              Descargar listado de contactos
            </Button>
          </Col>
            <TableContacts />
        </Row>
      </WrapperCardContacts>
    </AdminMenuLayout>
  )
}
