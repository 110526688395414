import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { message } from "antd";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { createStore, refreshTableStores, selectIsUpdateStore, selectStoreForUpdate, selectStoreRefreshTable, setIsStoresForUpdate, storesChangeModalState, updateStore } from "../../../redux";
import { IStore } from "../../../redux/@types";
import { validateCreateStores, validateUpdateStores } from "../../../helpers";

export const useStores = () => {
  const dispatch = useAppDispatch();
  const isRefreshTableStore = useSelector(selectStoreRefreshTable);
  const isUpdateStore = useSelector(selectIsUpdateStore);
  const dataUpdateStore = useSelector(selectStoreForUpdate);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalStoresState = () => {
    dispatch(setIsStoresForUpdate(false));
    dispatch(storesChangeModalState());
  };

  const formMethodsCreate = useForm<IStore>({
    resolver: yupResolver(validateCreateStores),
  });

  const formMethodsUpdate = useForm<IStore>({
    resolver: yupResolver(validateUpdateStores),
  });

  useEffect(() => {
    if (isUpdateStore) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateStore });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateStore, dataUpdateStore]);


  const onSubmitCreateOrUpdate = async (data: IStore) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateStore) {
      //console.log("Actualiando===>", data);
      await updateStore({
        id: data.id,
        location_city_id: Number(data.location_city_id),
        name: data.name,
        phone: data.phone,
        phone2: data.phone2,
        email: data.email,
        address: data.address,
        schedule: data.schedule,
        cover_path_image: data.cover_path_image,
        is_pick_and_go:  data.is_pick_and_go,
      })
        .then(async(res: Partial<IStore>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(storesChangeModalState());
            message.success(
              `Se ha actualizado con éxito la tienda con id:${res.id}`
            );
            dispatch(refreshTableStores(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createStore({
        ...data,
        is_pick_and_go:  data.is_pick_and_go,
        location_city_id: Number(data.location_city_id),
      })
        .then((res: IStore) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(storesChangeModalState());
            message.success(
              `Se ha creado con éxito la tienda con id:${res.id}`
            );
            dispatch(refreshTableStores(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableStore) {
      tableProps?.refresh();
      dispatch(refreshTableStores(false));
    }
  }, [isRefreshTableStore]);

return {
  //state
  isUpdateStore,
  isLoading,
  tableProps,
  //methods
  formMethodsCreate,
  formMethodsUpdate,
  setTableProps,
  //functions
  onSubmitCreateOrUpdate,
  changeModalStoresState
}
}
