import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import {
  addressesUsersChangeModalState,
  selectAddressesUsersForUpdate,
  selectAddressesUsersRefreshTable,
  selectIsUpdateAddressesUsers,
  setIsAddressesUserForUpdate,
  refreshTableAddressesUser,
  createAddressesUser,
  updateAddressesUser,
  getStates,
  getCities,
} from "../../../redux";
import { useEffect, useState } from "react";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IAddressesUsersFind,
  ICitiesRes,
  IStatesRes,
} from "../../../redux/@types";
import { message } from "antd";
import { citiesService, statesService } from "../../../shared/services";
import {
  selectStateAddressesUsers,
  selectCitiesAddressesUsers,
} from "../../../redux/selectors/addressesUsers.selectors";
import {
  validateCreateAddressesUsers,
  validateUpdateAddressesUsers,
} from "../../../helpers";

export const useAddressesUsers = () => {
  const dispatch = useAppDispatch();
  // const [states, setStates] = useState<IStatesRes | []>([]);
  // const [cities, setCities] = useState<ICitiesRes | []>([]);
  const isUpdateAddressesUsers = useSelector(selectIsUpdateAddressesUsers);
  const dataUpdateAddressesUsers = useSelector(selectAddressesUsersForUpdate);
  const statesAddressesUsers = useSelector(selectStateAddressesUsers);
  const citiesAddressesUsers = useSelector(selectCitiesAddressesUsers);
  const isRefreshTableAddressesUsers = useSelector(
    selectAddressesUsersRefreshTable
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const servicesPlaces = async () => {
    await statesService
      .find({ query: { $limit: 999999999 } })
      .then(({ data }: IStatesRes) => {
        //console.log("states====>", data);
        dispatch(getStates(data));
      });
    await citiesService
      .find({ query: { $limit: 999999999 } })
      .then(({ data }: any) => {
        // console.log("cities====>", data);
        dispatch(getCities(data as ICitiesRes[]));
      });
  };

  useEffect(() => {
    if (!statesAddressesUsers && !citiesAddressesUsers) {
      servicesPlaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeModalAddressesUsersState = () => {
    dispatch(setIsAddressesUserForUpdate(false));
    dispatch(addressesUsersChangeModalState());
  };

  const formMethodsCreate = useForm<IAddressesUsersFind>({
    resolver: yupResolver(validateCreateAddressesUsers),
  });

  const formMethodsUpdate = useForm<IAddressesUsersFind>({
    resolver: yupResolver(validateUpdateAddressesUsers),
  });
  // console.log({ dataUpdateAddressesUsers });
  useEffect(() => {
    if (isUpdateAddressesUsers) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateAddressesUsers });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateAddressesUsers, dataUpdateAddressesUsers]);

  const onSubmitCreateOrUpdate = async (data: IAddressesUsersFind) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateAddressesUsers) {
      //console.log("Actualiando===>", data);
      await updateAddressesUser({
        id: data.id,
        name: data.name,
        address: data.address,
        state_id: Number(data.state_id),
        city_id: Number(data.city_id),
        lat: data.lat,
        lng: data.lng,
        details: data.details,
      })
        .then((res: Partial<IAddressesUsersFind>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(addressesUsersChangeModalState());
            message.success(
              `Se ha actualizado con éxito la dirección del usuario con id:${res.id}`
            );
            dispatch(refreshTableAddressesUser(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createAddressesUser(data)
        .then((res: IAddressesUsersFind) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(addressesUsersChangeModalState());
            message.success(
              `Se ha creado con éxito la dirección del usuario con id:${res.id}`
            );
            dispatch(refreshTableAddressesUser(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableAddressesUsers) {
      tableProps?.refresh();
      dispatch(refreshTableAddressesUser(false));
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableAddressesUsers]);

  return {
    //state
    isUpdateAddressesUsers,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalAddressesUsersState,
  };
};
