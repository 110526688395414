import { useSelector } from "react-redux";
import { useUsers } from "../../../../modules/users/hooks/useUsers";
import {
  selectModalUser,
  selectIsUpdateUser,
  selectUserForUpdate,
} from "../../../../redux";
import { WrapperModalUsers } from "./styled";
import { useFormContext } from "react-hook-form";
import { IUserResFind } from "../../../../redux/@types";
import { Button, Col, Row, Table, Typography, message } from "antd";
import { InputNumber, InputPassword, InputSelect, InputText } from "../inputs";
import { useEffect, useState } from "react";
import { addressesUsersService } from "../../../services";

interface PropsAdressesUser {
  id:          number;
  name:        null;
  address:     string;
  user_id:     number;
  city_id:     number;
  city_name:   string;
  state_id:    number;
  state_name:  string;
  lat:         number;
  lng:         number;
  details:     null | string;
  main:        string;
  postal_code: string;
  createdAt:   Date;
  updatedAt:   Date;
  deletedAt:   null;
  user:        User;
  state:       City;
  city:        City;
}

interface City {
  id:             number;
  name:           string;
  integration_id: string;
  state_id?:      number;
  dane_code:      string;
  createdAt:      Date;
  updatedAt:      Date;
  deletedAt:      null;
  region?:        string;
}


interface User {
  email:           string;
  first_name:      string;
  last_name:       string;
  phone:           string;
  gender:          null;
  profile_picture: null;
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Dirección',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Region',
    dataIndex: 'state_name',
    key: 'state_name',
  },
  {
    title: 'Ciudad',
    dataIndex: 'city_name',
    key: 'city_name',
  },
];

const { Title } = Typography;

const dataOptionsRole = [
  { value: "user", title: "Usuario" },
  { value: "seller", title: "Vendedor" },
  { value: "admin", title: "Administrador" },
];

const dataOptionsStatus = [
  { value: "active", title: "Activo" },
  { value: "inactive", title: "Inactivo" },
  {
    value: "pending security verification",
    title: "Verificación de seguridad pendiente",
  },
  { value: "pending user data", title: "Datos de usuario pendientes" },
  { value: "pending validation", title: "Validación pendiente" },
];

export const ModalUsers = () => {
  const modalUser = useSelector(selectModalUser);
  const isUpdateUser = useSelector(selectIsUpdateUser);
  const updateUser = useSelector(selectUserForUpdate);
  const { isLoading, changeModalUserState, onSubmitCreateOrUpdate } =
    useUsers();

  const { control, handleSubmit: onSubmit } = useFormContext<IUserResFind>();
  const [addressesUsers, setAddressesUsers] = useState<PropsAdressesUser[] | [] | null>(null)

  const getAddressesByUsers = async(user_id: number) =>{
    return await addressesUsersService
    .find({
      query: { user_id, $limit: 9999999999999 },
    })
    .then((res) => {
      return setAddressesUsers(res.data);
    })
    .catch((err: any) => {
      console.log(err);
      message.error(err.message);
      return setAddressesUsers([]);
    });
  }

  useEffect(() => {
    if(isUpdateUser && updateUser){
      getAddressesByUsers(updateUser?.id)
    }

    return () => {
      setAddressesUsers(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <WrapperModalUsers
      title={
        isUpdateUser
          ? `Actualizando usuario: '${updateUser?.id!}'`
          : `Creando usuario`
      }
      open={modalUser}
      width={"640px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalUserState}
    >
      <Row gutter={16}>
        <Col
          span={12}
          style={{
            display: "flex",
            margin:"0",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre:
          </Title>
          <InputText
            placeHolder="Juan David"
            name="first_name"
            control={control}
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Apellidos:
          </Title>
          <InputText
            placeHolder="Velázquez Molina"
            name="last_name"
            control={control}
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Cédula:
          </Title>
          <InputNumber
            stringMode={true}
            placeHolder="123456789"
            name="dni"
            control={control}
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Correo:
          </Title>
          <InputText
            placeHolder="example@example.com"
            name="email"
            control={control}
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Contraseña:
          </Title>
          <InputPassword name="password" control={control} />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Teléfono:
          </Title>
          <InputNumber
            stringMode={true}
            placeHolder="3292321143"
            name="phone"
            control={control}
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Rol:
          </Title>
          <InputSelect
            name="role"
            control={control}
            dataOptions={dataOptionsRole}
            label="Role"
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Estado:
          </Title>
          <InputSelect
            name="status"
            control={control}
            dataOptions={dataOptionsStatus}
            label="Estado"
          />
        </Col>
      </Row>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateUser ? "Actualizar información" : "Crear usuario"}
      </Button>
      {
        addressesUsers && addressesUsers?.length > 0 &&(
          <>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Direcciones asociadas
            </Title>
            <Table dataSource={addressesUsers} columns={columns} />
          </>
        )
      }
    </WrapperModalUsers>
  );
};
