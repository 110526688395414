import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { message } from "antd";

import {
  createPriceList,
  priceListChangeModalState,
  refreshTablePriceList,
  selectIsUpdatePriceList,
  selectPriceListForUpdate,
  selectPriceListRefreshTable,
  setIsPriceListForUpdate,
  updatePriceList,
} from "../../../redux";
import { ISinglePriceList } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import {
  validateCreatePricesList,
  validateUpdatePricesList,
} from "../../../helpers";
import moment from "moment";

export const usePriceList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isRefreshTablePriceList = useSelector(selectPriceListRefreshTable);
  const isUpdatePriceList = useSelector(selectIsUpdatePriceList);
  const dataUpdatePriceList = useSelector(selectPriceListForUpdate);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalPriceListState = () => {
    dispatch(priceListChangeModalState());
  };

  const changeCreatePriceListState = () => {
    dispatch(setIsPriceListForUpdate(false));
    navigate(`/priceList/new`);
  };

  const formMethodsCreate = useForm<ISinglePriceList>({
    resolver: yupResolver(validateCreatePricesList),
  });

  const formMethodsUpdate = useForm<ISinglePriceList>({
    resolver: yupResolver(validateUpdatePricesList),
  });

  useEffect(() => {
    if (isUpdatePriceList) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdatePriceList });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatePriceList, dataUpdatePriceList]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updatePriceList({
      id,
      status: value,
    })
    .then((res: Partial<ISinglePriceList>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado de la lista de precios con id:${res.id}`
        );
        dispatch(refreshTablePriceList(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: ISinglePriceList) => {
    console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdatePriceList) {
      await updatePriceList({
        id: data.id,
        name: data.name,
        ...(data.description && {description: data.description.replace(/&nbsp;/g, ' ')}),
        start_at: moment(data.start_at).format(),
        ends_at: moment(data.ends_at).format(),
        status: data.status,
        priority: data.priority,
        prices: data.prices,
        customer_groups: data.customer_groups,
      }, {
        query:{
          $client: {
            resetAll: "true"
          }
        },
      })
        .then((res: Partial<ISinglePriceList>) => {
          setIsLoading(false);
          if (res.id) {
            navigate(`/priceList`);
            message.success(
              `Se ha actualizado con éxito la lista de precios con id:${res.id}`
            );
            dispatch(refreshTablePriceList(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createPriceList({
        name: data.name,
        ...(data.description && {description: data.description.replace(/&nbsp;/g, ' ')}),
        start_at: moment(data.start_at).format(),
        ends_at: moment(data.ends_at).format(),
        status: data.status,
        priority: data.priority,
        prices: data.prices,
        customer_groups: data.customer_groups,
      }, {
        query:{
          $client: {
            resetAll: "true"
          }
        },
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            navigate(`/priceList`);
            message.success(
              `Se ha creado con éxito la lista de precios con id:${res.id}`
            );
            dispatch(refreshTablePriceList(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTablePriceList) {
      tableProps?.refresh();
      dispatch(refreshTablePriceList(false));
    }
  }, [isRefreshTablePriceList]);
  return {
    //state
    tableProps,
    isUpdatePriceList,
    isLoading,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeCreatePriceListState,
    onSubmitCreateOrUpdate,
    changeModalPriceListState,
    updateStatus,
  };
};
