import { Button, Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { selectCMSForUpdate, selectIsUpdateCMS } from "../../../../redux";
import { DataCMS } from "../../../../redux/@types/cms";
import {
  AdminMenuLayout,
  InputHTML,
  InputSelect,
  InputText,
} from "../../../../shared/components";
import { useCMS } from "../../hook";
import { WrapperCardCMS } from "../styled";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const { Title } = Typography;
export const CMSById = () => {
  const { id } = useParams();
  const isUpdateCMS = useSelector(selectIsUpdateCMS);
  const updateCMS = useSelector(selectCMSForUpdate);
  const { isLoading, onSubmitCreateOrUpdate } = useCMS();

  const { control, handleSubmit: onSubmit } = useFormContext<DataCMS>();

  return (
    <AdminMenuLayout pageDescription="CMSById" title="CMSById">
      <WrapperCardCMS>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              // padding: 5,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                marginBottom: "1.5px",
                color: "var(--primary)",
                marginRight: 7,
              }}
              level={2}
            >
              {isUpdateCMS
                ? `Actualizando CMS: ${updateCMS?.id} `
                : `Creando CMS`}
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Título:
            </Title>
            <InputText placeHolder="CMS 1" name="title" control={control} />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Estado:
            </Title>
            <InputSelect
              name="status"
              control={control}
              dataOptions={[
                { title: "Activo", value: "active" },
                { title: "Inactivo", value: "inactive" },
              ]}
              label="Estado"
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "7.5px" }} level={5}>
              Contenido:
            </Title>
            {isUpdateCMS ? (
              <InputHTML
                placeHolder="Lea atentamente antes de aceptar...."
                name="body"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Lea atentamente antes de aceptar...."
                name="body"
                control={control}
              />
            )}
          </Col>
          <Button
            style={{
              width: "100%",
              marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
          >
            {isUpdateCMS ? `Actualizar información` : `Crear CMS`}
          </Button>
        </Row>
      </WrapperCardCMS>
    </AdminMenuLayout>
  );
};
