import { Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { rankingsService } from "../../../shared/services";
import { DataIRankings } from "../../../redux/@types/rankings";
import { useRankings, useTableRankings } from "../hooks";

const { Title } = Typography;

export const TableRankings = () => {
  const { showDeleteConfirm, changeRankingsForUpdate } = useTableRankings();
  const { setTableProps } = useRankings();
  const columns: TableColumns<DataIRankings> = [
    {
      title: "ID",
      width: 5,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "first_name",
      width: 11,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item.user[0]?.first_name}
            </Title>
          </Col>
        );
      },
      filterType: "text",
    },
    {
      title: "Apellido",
      key: "apellido",
      dataIndex: "last_name",
      width: 11,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item.user[0]?.last_name}
            </Title>
          </Col>
        );
      },
      filterType: "text",
    },
    {
      title: "Orden",
      key: "order_id",
      dataIndex: "order_id",
      width: 10,
    },
    {
      title: "Producto",
      key: "product_id",
      dataIndex: "product_id",
      width: 10,
    },
    {
      title: "Puntuación",
      key: "score",
      dataIndex: "score",
      width: 10,
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 10,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeRankingsForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.user_id}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataIRankings>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={rankingsService}
      onLoad={setTableProps}
    />
  );
};
