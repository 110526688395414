import * as yup from 'yup'

export const validateUpdateProductCategories = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    parent_id: yup
    .number()
    .nullable()
    .typeError('Campo requerido.')
    .positive()
    .min(1, 'Debe de utilizar un numero que no sea negativo'),
    position:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    // path_icon:yup
    // .string()
    // .min(2, 'El ICONO debe tener más de 2 caracteres'),
    // path_icon2:yup
    // .string()
    // .min(2, 'El ICONO 2 debe tener más de 2 caracteres'),
    // path_image:yup
    // .string()
    // .min(2, 'La IMAGEN debe tener más de 2 caracteres'),
    status:yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    description: yup
    .string()
    .nullable()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    banner_desktop_path:yup
    .string()
    .typeError('Campo requerido.')
    .min(2, 'EL BANNER DE DESKTOP debe tener más de 2 caracteres'),
    // banner_movile_path:yup
    // .string()
    // .min(2, 'EL BANNER DE MOBIL debe tener más de 2 caracteres'),

})

