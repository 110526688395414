import { citiesService, storesService } from "../../shared/services"
import { IGenericArrStores, IStore } from "../@types"
import { storesActions } from "../action-types"

export const storesChangeModalState = (): storesActions.IStoresChangeModalState => ({
    type: storesActions.STORES_CHANGE_MODAL_STATE
})

export const setStoresForUpdate = (val: IStore): storesActions.IGetStoresForUpdate => ({
    type: storesActions.GET_STORE_FOR_UPDATE,
    payload: val
})

export const setIsStoresForUpdate = (val: boolean): storesActions.IIsStoresForUpdate =>({
    type: storesActions.IS_STORE_FOR_UPDATE,
    payload: val
})

export const createStore = async(data: Partial<IStore>) => {
    return storesService.create(data)
    .then((res: IStore) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateStore = async(data:Partial<IStore>) => {
    return await storesService.patch(data?.id!, data)
    .then((res: IStore) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteStore = async (id: number ) =>{
    return  await storesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableStores =( val: boolean ): storesActions.ISwitchStoresRefreshTable=>({
    type: storesActions.SWITCH_STORES_REFRESH_TABLE,
    payload: val
})

export const getCitiesArr = async () => {
    return await citiesService.find({
        query:{
            $select: ["id","name"],
            $limit: 9999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setCitiesStoresArr = (val:IGenericArrStores[]): storesActions.IGetCitiesForStores =>({
    type: storesActions.GET_CITIES_FOR_STORES,
    payload: val
})