// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { message, Select, TreeSelect, Typography } from "antd";
import { useEffect } from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { InputError } from "./styled";


const { Option } = Select;
const { Title } = Typography;

export interface PropsArrCategoriesFuncRes {
    value:        number;
    title:      string;
    parent_id: number;
    path:      string;
    children:  PropsArrCategoriesFuncRes[];
  }

interface Props {
    dataOptions: PropsArrCategoriesFuncRes[];
    width?: string;
    label: string;
    name: string;
    control: any;
    showSearch?: boolean;
    defaultValue?: string;
    isClearable?: boolean;
  }

interface PropsContent {
    errors: Partial<FieldErrorsImpl<{
      [x: string]: any;
    }>>;
    name: string;
  }

const ErrorComponent = ({errors,name}:PropsContent) =>{
    useEffect(() => {
      if(errors?.[name]?.message){
        message.error(`${errors?.[name]?.message}`)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])
    return(
      <>
      {!!errors[name] && (
        <InputError>{errors?.[name]?.message}</InputError>
        )}
        </>
    )
  }

export const InputSelectTree = ({
    width = "100%",
    dataOptions,
    label,
    name,
    control,
    showSearch = false,
    defaultValue,
    isClearable = false,
  }: Props) => {
  return (
    <Controller
    defaultValue={!!defaultValue ? `${defaultValue}` : undefined }
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        console.log({value})
        return (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TreeSelect
                showSearch
                style={{ width }}
                // defaultValue={value.toString()}
                value={value}
                status={errors?.[name]?.message && "error" }
                // dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={label}
                allowClear={isClearable}
                treeDefaultExpandAll
                onChange={onChange}
                treeData={dataOptions}
            />
            <ErrorComponent errors={errors} name={name}/>
          </div>
        );
      }}
    />
  )
}
