import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { createFulfillmentCompany, fulfillmentCompanyChangeModalState, refreshTableFulfillmentCompany, selectIsUpdateFulfillmentCompany, selectFulfillmentCompanyForUpdate, selectFulfillmentCompanyRefreshTable, setIsFulfillmentCompanyForUpdate, updateFulfillmentCompany } from "../../../redux";
import { DataFulfillmentCompany } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { validateCreateFulfillmentCompany, validateUpdateFulfillmentCompany } from '../../../helpers';

export const useFulfillmentCompany = () => {
    const dispatch = useAppDispatch();

    const isUpdateFulfillmentCompany = useSelector(
      selectIsUpdateFulfillmentCompany
    );
    const dataUpdateFulfillmentCompany = useSelector(
      selectFulfillmentCompanyForUpdate
    );
    const isRefreshTableFulfillmentCompany = useSelector(
      selectFulfillmentCompanyRefreshTable
    );

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const changeModalFulfillmentCompanyState = () => {
      dispatch(setIsFulfillmentCompanyForUpdate(false));
      dispatch(fulfillmentCompanyChangeModalState());
    };

    const formMethodsCreate = useForm<DataFulfillmentCompany>({
      resolver: yupResolver(validateCreateFulfillmentCompany),
    });

    const formMethodsUpdate = useForm<DataFulfillmentCompany>({
      resolver: yupResolver(validateUpdateFulfillmentCompany),
    });

    useEffect(() => {
      if (isUpdateFulfillmentCompany) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateFulfillmentCompany });
      }

      return () => {
        formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateFulfillmentCompany, dataUpdateFulfillmentCompany]);


    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await updateFulfillmentCompany({
        id,
        status: value,
      })
      .then((res: Partial<DataFulfillmentCompany>) => {
        setIsLoading(false);
        if (res.id) {
          // dispatch(bannersChangeModalState());
          message.success(
            `Se ha actualizado con éxito el estado del bonus con id:${res.id}`
          );
          dispatch(refreshTableFulfillmentCompany(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: DataFulfillmentCompany) => {
      // console.log({ data });
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateFulfillmentCompany) {
        //console.log("Actualizando===>", data);
        await updateFulfillmentCompany({
          id: data.id,
          name: data.name,
          ...(data.description && {description: data.description.replace(/&nbsp;/g, ' ')}),
          // path: data.path,
          type: data.type,
          status: data.status,
          integration: data.integration,
        })
          .then(async(res: Partial<DataFulfillmentCompany>) => {
            setIsLoading(false);
            // dispatch(mappingUpdatedUser(res))
            if (res.id) {
              // dispatch(fulfillmentCompanyChangeModalState());
              message.success(
                `Se ha actualizado con éxito el bonus con id:${res.id}`
              );
              dispatch(refreshTableFulfillmentCompany(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      } else {
        // console.log('Creando===>',data)
        await createFulfillmentCompany(data)
          .then((res: DataFulfillmentCompany) => {
            setIsLoading(false);
            if (res.id) {
              dispatch(fulfillmentCompanyChangeModalState());
              message.success(
                `Se ha creado con éxito el bonus con id:${res.id}`
              );
              dispatch(refreshTableFulfillmentCompany(true));
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      }
    };

    useEffect(() => {
      if (isRefreshTableFulfillmentCompany) {
        tableProps?.refresh();
        dispatch(refreshTableFulfillmentCompany(false));
      }
    }, [isRefreshTableFulfillmentCompany]);

  return {
    //state
    isUpdateFulfillmentCompany,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalFulfillmentCompanyState,
    updateStatus,
  }
}
