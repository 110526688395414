import * as yup from 'yup'

export const validateUpdateCourses = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    status:yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    product_id: yup
    .number().typeError('Campo requerido.')
    .positive(),
    category_id: yup
    .number().typeError('Campo requerido.')
    .positive(),
    rating_score: yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    small_description:yup
    .string()
    .min(2, 'LA DESCRIPCIÓN CORTA debe tener más de 2 caracteres'),
    description:yup
    .string()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    level: yup
    .string()
    .oneOf(['principiante', 'avanzado'], 'Debes elegir entre estas opciones'),
    duration: yup
    .string(),
    authors:yup
    .string()
    .min(2, 'El AUTOR debe tener más de 2 caracteres'),
    path_video:yup
    .string()
    .min(2, 'El VIDEO debe tener más de 2 caracteres'),
    path_image:yup
    .string()
    .min(2, 'La IMAGEN debe tener más de 2 caracteres'),
    position:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
})