// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { selectContactsDirectoryForUpdate } from "../../../redux";
import { contactsDirectoryAttributes } from "../../../shared/services";
import { IAttribute } from "../../../redux/@types/contactsDirectory";
import { useParams } from "react-router-dom";

export interface IDataAtt {
  id: number;
  name: string;
  icon: null;
  contact_directory_id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

const initialStateForm = {
  textValueAtt: undefined,
  iconAtt: undefined,
};

const initialStateFormForUpdate = {
  idForUpdate: undefined,
  textValueAttForUpdate: undefined,
  iconAttForUpdate: undefined,
};

const { confirm } = Modal;

export const useContactsAttributes = () => {
  const params = useParams();
  const [isLoadingAtt, setIsLoadingAtt] = useState(false);
  const [isCreateAtt, setIsCreateAtt] = useState(false);
  const [isEditeAtt, setIsEditeAtt] = useState(false);
  const [formAttributesForUpdate, setFormAttributesForUpdate] = useState<{
    idForUpdate: number | undefined;
    textValueAttForUpdate: string | undefined;
    iconAttForUpdate: string | undefined;
  }>(initialStateFormForUpdate);
  const [arrOfAtt, setArrOfAtt] = useState<IDataAtt[] | []>([]);
  const [formAttributes, setFormAttributes] = useState<{
    textValueAtt: string | undefined;
    iconAtt: string | undefined;
  }>(initialStateForm);
  const { iconAtt, textValueAtt } = formAttributes;
  const { idForUpdate, textValueAttForUpdate, iconAttForUpdate } =
    formAttributesForUpdate;
  console.log({ formAttributesForUpdate });
  const getArrOfContContactsAttributes = async () => {
    return await contactsDirectoryAttributes
      .find({
        query: { contact_directory_id: params.id, $limit: 999999999 },
      })
      .then((res: any) => {
        setArrOfAtt(res.data);
      })
      .catch((err: any) => {
        message.error(`Error inesperado por favor revise la consola `);
        console.log(err.message);
        setArrOfAtt([]);
      });
  };
  useEffect(() => {
    getArrOfContContactsAttributes();
  }, []);

  const createAttributes = async () => {
    setIsLoadingAtt(true);
    if (!textValueAtt)
      return message.error(
        `Por lo menos debes de tener el nombre del atributo definido para ejecutar esta acción.`
      );
    return await contactsDirectoryAttributes
      .create({
        name: textValueAtt,
        icon: iconAtt || undefined,
        contact_directory_id: params.id,
      })
      .then((res: any) => {
        setIsCreateAtt(false);
        message.success(`Se ha guardado el atributo "${textValueAtt}"`);
        setFormAttributes({ ...initialStateForm });
        setIsLoadingAtt(false);
        return getArrOfContContactsAttributes();
      })
      .catch((err: any) => {
        setIsEditeAtt(false);
        console.log(err.message);
        return err.message;
      });
  };

  const updateAttributes = async (idForUpdate: number) => {
    setIsLoadingAtt(true);
    return await contactsDirectoryAttributes
      .patch(idForUpdate, {
        name: textValueAttForUpdate,
        icon: iconAttForUpdate || undefined,
      })
      .then((res: any) => {
        setIsEditeAtt(false);
        message.success(`Se ha actualizado el atributo "${textValueAtt}"`);
        setFormAttributesForUpdate({ ...initialStateFormForUpdate });
        setIsLoadingAtt(false);
        return getArrOfContContactsAttributes();
      })
      .catch((err: any) => {
        setIsEditeAtt(false);
        console.log(err.message);
        return err.message;
      });
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el Atributo con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar atributo" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar atributo",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        setIsCreateAtt(false);
        return await contactsDirectoryAttributes
          .remove(valId)
          .then((res: any) => {
            setIsCreateAtt(false);
            message.success(`Se ha borrado el atributo "${res.name}"`);
            return getArrOfContContactsAttributes();
          })
          .catch((err: any) => {
            setIsCreateAtt(false);
            console.log(err.message);
            return err.message;
          });
      },
      onCancel() {},
    });
  };

  const closeCreateCard = () => {
    setFormAttributes({ ...initialStateForm });
    setIsCreateAtt(false);
  };

  const changeUpdateAtt = (attForUpdate: IDataAtt) => {
    setIsEditeAtt(true);
    setFormAttributesForUpdate({
      iconAttForUpdate: attForUpdate.icon,
      idForUpdate: attForUpdate.id,
      textValueAttForUpdate: attForUpdate.name,
    });
  };

  const closeUpdateAtt = () => {
    setIsEditeAtt(false);
    setFormAttributesForUpdate({ ...initialStateFormForUpdate });
  };

  return {
    //state
    formAttributes,
    formAttributesForUpdate,
    iconAtt,
    textValueAtt,
    idForUpdate,
    textValueAttForUpdate,
    iconAttForUpdate,
    isLoadingAtt,
    isCreateAtt,
    isEditeAtt,
    arrOfAtt,
    //methods
    setFormAttributes,
    setIsCreateAtt,
    setIsEditeAtt,
    setIsLoadingAtt,
    setFormAttributesForUpdate,
    //functions
    createAttributes,
    updateAttributes,
    showDeleteConfirm,
    closeCreateCard,
    changeUpdateAtt,
    closeUpdateAtt,
  };
};
