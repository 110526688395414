import { Button, Col } from "antd";
import Table from "../../../shared/components/ui/Table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { addressesUsersService } from "../../../shared/services";
import { useTableAddressesUsers } from "../hooks/useTableAddressesUsers";
import { IAddressesUsersFind } from "../../../redux/@types";

export const TableAddressesUsers = () => {
  const { changeAddressesUsersForUpdate, showDeleteConfirm } =
    useTableAddressesUsers();
  const columns: TableColumns<IAddressesUsersFind> = [
    {
      title: "Usuario ID",
      width: 30,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Nombre",
      width: 70,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Dirección",
      width: 70,
      dataIndex: "address",
      key: "address",
      filterType: "text",
    },
    {
      title: "Estado",
      dataIndex: ["state", "name"],
      key: "state",
      width: 40,
      filters: [
        {
          text: "Atlántico",
          value: "Atlántico",
        },
      ],
    },
    {
      title: "Ciudad",
      dataIndex: ["city", "name"],
      key: "name",
      width: 50,
      filters: [
        {
          text: "Barranquilla",
          value: "Barranquilla",
        },
      ],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 30,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeAddressesUsersForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<IAddressesUsersFind>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={addressesUsersService}
      // onLoad={}
    />
  );
};
